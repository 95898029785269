<template>
  <div>
    <waste-exchange-main></waste-exchange-main>
  </div>
</template>

<script>
import WasteExchangeMain from "@/components/ecologist/wasteExchange/wasteExchangeMain";

export default {
  name: "wasteExchangeMainView",
  components: { WasteExchangeMain },
};
</script>

<style scoped></style>
